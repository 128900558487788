.areablock--teasergrid {
}
.teasergrid-item {
    text-align: left;
    display: grid;
    text-decoration: none;

    grid-template-columns: 50% 50%;

    &:hover {
        text-decoration: none;
    }
}
.teasergrid-info {
    display: grid;
    place-items: center;
    text-align: center;
    h3 {
        color: $color-ci-primary;
        @include fluid(font-size, 18px);
        @include fluid(line-height, 22px);
        margin-top: 0;
        @include fluid(margin-bottom, 20px);
    }
    .teasergrid-icon {
        width: 40%;
        height: 0;
        padding-bottom: 40%;
        display: block;
        margin: 0 auto;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;
        }
    }
}
.teasergrid-info--items {
    width: 100%;
}
.teasergrid-image {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/* Mobile Only */
@include media-breakpoint-down(md) {
    .areablock--teasergrid {
        .container {
            .teasergrid-item:nth-child(2n) {
                direction: rtl;
            }
        }
    }
}

/* Tablet & Desktop */
@include media-breakpoint-up(md) {
    .areablock--teasergrid {
        .container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
    }
    .teasergrid-item {
        grid-template-columns: 33.333% 66.666%;
        border: 1px solid #E8E8E8;
    }
    .teasergrid-image {
        padding-bottom: 50.4%;
    }
}