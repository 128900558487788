.areablock--imageteaser {
    position: relative;
}
.imageteaser--image {
    img {
        width: 100%;
        height: auto;
    }
}
.imageteaser-box {
    background: black;
    z-index: 1;

    display: grid;
    place-items: center;
    text-align: center;
    
    @include fluid(width, 160px);
    @include fluid(height, 160px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    h3 {
        color: $color-ci-primary;
        margin: 0;
        @include fluid(font-size, 18px);
        @include fluid(line-height, 21px);
        @include fluid(margin-bottom, 15px);
    }
}

.imageteaser-svg {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
    }
}
.imageteaser-text {
    background: $color-ci-primary;
    color: $black;
    @include fluid(padding-top, 126px);
    @include fluid(padding-right, 26px);
    @include fluid(padding-bottom, 60px);
    @include fluid(padding-left, 26px);
    p {
        font-family: 'Barlow Condensed';
        font-weight: 600;
        @include fluid(font-size, 23px);
        @include fluid(line-height, 27.6px);
        @include fluid(margin-bottom, 30px);
    }
}

@media (min-width: 1024px) {
    .imageteaser-box-text {
        position: absolute;
        right: 0;
        bottom: 0;
        display: grid;
        grid-template-columns: 320px auto;
        width: 955px;
    }
    .imageteaser-box {
        position: relative;
        transform: none;
        left: unset;
        width: 320px;
        height: 320px;
        h3 {
            font-size: 23px;
            line-height: 27.6px;
        }
    }
    .imageteaser-svg {
        width: 120px;
    }
    .imageteaser-text {
        font-size: 23px;
        line-height: 27.6px;
        text-align: left;
        padding-top: 92px;
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media (min-width: 1400px) {
    .imageteaser-box-text {
        bottom: 70px;
    }
}
@media (min-width: 1600px) {
    .imageteaser-box-text {
        bottom: 160px;
    }
}
