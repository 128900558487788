// Bootstrap display utility classes for list items
@include media-breakpoint-up(sm) { .d-sm-list-item { display: list-item !important; } }
@include media-breakpoint-up(md) { .d-md-list-item { display: list-item !important;; } }
@include media-breakpoint-up(lg) { .d-lg-list-item { display: list-item !important;; } }
@include media-breakpoint-up(xl) { .d-xl-list-item { display: list-item !important;; } }
@include media-breakpoint-up(xxl) { .d-xxl-list-item { display: list-item !important;; } }
@include media-breakpoint-up(xxxl) { .d-xxxl-list-item { display: list-item !important;; } }

.mb-40 {
    margin-bottom: 40px !important;
}