
@function strip-units($number) {
    @return $number / ($number * 0 + 1);
}

@function get-font-size-base-in-px($font-size-root: $font-size-root) {
    @return $font-size-root;
}

@function to-rem($px, $font-size-base: $font-size-base) {
    @if is-rem($font-size-base) {
        $font-size-base: strip-units($font-size-base) * strip-units( get-font-size-base-in-px($font-size-root) ) * 1px;
    }

    @if is-rem($px) {
        $px: strip-units($px) * strip-units( get-font-size-base-in-px($font-size-root) ) * 1px;
    }

    @return ((strip-units($px) / strip-units($font-size-base))) * 1rem;
}

@function to-em($px, $font-size-base: $font-size-base) {
    @if is-rem($font-size-base) {
        $font-size-base: strip-units($font-size-base) * strip-units( get-font-size-base-in-px($font-size-root) ) * 1px;
    }

    @if is-rem($px) {
        $px: strip-units($px) * strip-units( get-font-size-base-in-px($font-size-root) ) * 1px;
    }

    @return ((strip-units($px) / strip-units($font-size-base))) * 1em;
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

/* Fluid for mobile (375px) */
@function pxToVwMobile($value) {
    @return strip-unit($value) * 100 / strip-unit(375) + vw;
}
@function pxToVwMobileStripped($value) {
    @return strip-unit($value) * 100 / strip-unit(375);
}