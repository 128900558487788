$colorBackground: $color-ci-primary;
$color1: $color-ci-primary;
$color2: black;
$color3: white;

@mixin fluidNav($property, $value) {
    #{$property}: $value;
    @include media-breakpoint-down(sm) {
        #{$property}: pxToVwMobile($value);
    }
}

.main-nav--toggle {
    display: none;
}

// mobile menu

@include media-breakpoint-down(xxl) {

    // toggler & burger

    $burgerWidth: 25px;
    $burgerLineHeight: 2px;
    $burgerDistanceBetweenLines: 5px;
    $burgerPositionTop: 17px;
    $burgerPositionRight: 15px;
    $burgerHeight: ($burgerLineHeight * 3) + ($burgerDistanceBetweenLines * 2);

    $topBottomValue: ($burgerLineHeight + $burgerDistanceBetweenLines) * -1;

    .main-nav--burger {
        @include fluidNav(width, $burgerWidth);
        @include fluidNav(height, 50px);
        align-self: center;
        cursor: pointer;
        transition: all ease-in-out .15s;
        display: grid;
        place-items: center;
        border-left: 1px solid rgba(255, 255, 255, 0);
        z-index: 20001;
        position: fixed;

        span {
            display: block;
            @include fluidNav(width, $burgerWidth);
            @include fluidNav(height, $burgerLineHeight);
            background: $black;
            position: relative;
            transition: all ease-in-out .3s;
            &:before,
            &:after {
                content: '';
                display: block;
                @include fluidNav(width, $burgerWidth);
                @include fluidNav(height, $burgerLineHeight);
                min-height: $burgerLineHeight;
                background: $black;
                position: absolute;
                left: 0;
                transition: all ease-in-out .3s;
                opacity: 1;
            }


            &:before {
                @include fluidNav(top, $topBottomValue);
                transform-origin: center top;
            }

            &:after {
                @include fluidNav(bottom, $topBottomValue);
                transform-origin: center bottom;
            }
        }

    }

    // toggler checked


    .main-nav--toggle:checked ~ .header-main {

        // burger
        .main-nav--burger {
            transition: all ease-in-out .3s;
            span {
                background-color: transparent;
                &:before {
                    transform: rotate(45deg) translateX(pxToVwMobile(10px));
                    @include fluidNav(margin-left, -8px);
                    @include media-breakpoint-up(md) {
                        transform: rotate(45deg) translateX(10px);
                    }
                }
                &:after {
                    transform: rotate(-45deg) translateX(pxToVwMobile(10px));
                    @include fluidNav(margin-left, -8px);
                    @include media-breakpoint-up(md) {
                        transform: rotate(-45deg) translateX(10px);
                    }
                }
            }
        }

        // main-nav
        .main-nav {
            width: 100%;
            opacity: 1;
            position: fixed;
            transition: all ease-in-out .15s;
            @include media-breakpoint-up(md) {
                width: 375px;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
            }
        }

    }

    // main nav menu

    .main-nav {
        z-index: 20000;
        width: 0;
        height: calc(100vh - #{pxToVwMobile(49px)});
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        overflow-y: auto;
        background: $color1;
        @include fluid(padding-top, 100px);
        @include fluid(top, 50px);
        text-align: left;

        & > ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                display: block;
                position: relative;
            }

            a {
                display: block;
                color: $white;
                @include fluidNav(font-size, 15px);
                @include fluidNav(line-height, 22px);
                border: none;
                border-radius: 0;
                font-weight: 600;
                @include fluidNav(padding-top, 15px);
                @include fluidNav(padding-right, 63px);
                @include fluidNav(padding-bottom, 15px);
                @include fluidNav(padding-left, 63px);
                transition: background-color .3s ease-in-out;
                @include fluidNav(letter-spacing, 0.2px);

                &:hover {
                    color: $white;
                }

            }

            & > li > a {
                &:before {
                    content: '';
                    @include fluid(width, 8px);
                    @include fluid(height, 8px);
                    @include fluid(margin-top, 8px);
                    @include fluid(margin-right, 18px);
                    border: 1px solid $white;
                    transform: rotate(45deg);
                    display: inline-block;
                    float: left;
                    background-color: transparent;
                    transition: background-color ease-in-out .33s;
                }
            }

            li.main-nav--active {
                & > a {
                    &:before {
                        background-color: $white;
                    }
                }
            }
        }

        .main-nav--first-level {
            display: block;
        }

        // main nav sub menu

        .main-nav--sub {
            max-height: 0;
            overflow: hidden;
            transition: all ease-in-out .3s;
            background-color: transparent;
            border-top: none;
            padding-left: 0;

            li {
                border-bottom: none;
                &.main-nav--sub-active {
                    a {
                        opacity: .5;
                    }
                }
            }

            a {
                @include fluidNav(padding-top, 15px);
                @include fluidNav(padding-right, 63px);
                @include fluidNav(padding-bottom, 15px);
                @include fluidNav(padding-left, 88px);
            }
        }

    }

    // main nav dropdown icon

    .main-nav {
        .next {
            border: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 0;
            display: block;
            background: transparent;
        }

        .dropdown {
            display: none;
        }

        .dropdown:checked {
            & ~ a {
                &:before {
                    background-color: $white;
                }
            }
            & ~ .next {
                display: none;
            }
            & ~ .main-nav--sub {
                max-height: 1000px;
            }
        }
        li.main-nav--active {
            .next {
                display: none;
            }
            .main-nav--sub {
                max-height: 1000px;
            }
        }
    }

}

// mixed variant between desktop and mobile (aka tablet)

@include media-breakpoint-between(md, xxl) {
    body {
        .main-nav {
            right: -375px;
            height: 100vh;
            top: 0;
        }
        .main-nav--overlay {
            width: 100vw;
            height: 100vh;
            position: fixed;
            z-index: 9999;
            background: rgba(0,0,0,1);
            top: 0;
            right: -100vw;
            opacity: 0;
            transition: opacity ease-in-out .5s;
        }
        .main-nav--toggle:checked ~ .header-main {
            .main-nav {
                right: 0;
            }
            .main-nav--overlay {
                right: 0;
                opacity: .5;
            }
        }
    }
}



// standard menu

@include media-breakpoint-up(xxl) {

    $dropdownColor: darken($color1, 8%);

    .page-header--boxed {
        text-align: center;
    }

    .page-header--nav {
        //border-bottom: 1px solid rgba($white, .2);

        display: inline-block;

        &:hover {
            z-index: 99999;
        }
    }
    .main-nav--burger {
        display: none;
    }

    .mobile-menu-top,
    .mobile-menu-bottom {
        display: none;
    }

    // basics

    .main-nav {
        text-align: center;
        input {
            display: none;
        }

        & > ul {
            display: inline-block;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        // logo

        li.li-logo {
            position: relative;
            width: 120px;
            padding: 0;
            margin: 0 30px;
        }

        li.li-button {
            padding: 0 0 0 30px;
            display: none;
            a.btn {
                line-height: unset;
                font-size: 12px;
                color: $color2;
                &:hover {
                    color: $color3;
                }
                &.btn--outline-negative {
                    color: $white;
                    &:hover {
                        color: $color2;
                    }
                }
            }
        }

        a.logo {
            text-indent: -9999em;
            position: absolute;
            top: -25px;
            left: 0;
            width: 120px;
            height: 156px;
            padding: 0;
        }

    }

    // first level

    ul.main-nav--first-level {
        li {
            display: inline-block;
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
        }

        /*& > li:last-child {
          padding-right: 0;
        }*/

        li.main-nav--active:not(.li-logo):not(.li-button) {
            &:after {
                content: '';
                width: 10px;
                height: 10px;
                background: $white;
                display: inline-block;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }

        }

        a {
            display: block;
            line-height: 80px;
            color: $white;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
            position: relative;
        }
    }

    // main nav sub

    ul.main-nav--sub {
        position: absolute;
        top: 100%;
        left: -15px;
        min-width: 295px;
        padding-top: 30px;
        padding-bottom: 30px;
        background: $dropdownColor;
        box-shadow: 0 15px 45px rgba(0, 0, 0, 0.08);
        text-align: left;
        z-index: 1;
        margin-top: -1px;

        li {
            display: block;
            position: relative;
            transition: background .33s ease-in-out;
            padding: 0;
            /*&.main-nav--sub-active,
            &:hover {
              background: $color1dark;
            }*/
        }

        a {
            display: block;
            line-height: 22px;
            font-size: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 25px;
            margin-left: 33px;
            padding-right: 24px;
            font-weight: 700;
            color: $white;
            position: relative;
            &:before {
                content: '';
                @include fluid(width, 8px);
                @include fluid(height, 8px);
                @include fluid(margin-top, 8px);
                @include fluid(margin-right, 18px);
                border: 1px solid $white;
                transform: rotate(45deg);
                display: inline-block;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: transparent;
                transition: background-color ease-in-out .33s;
            }
        }

        li.main-nav--sub-active {
            a {
                &:before {
                    background-color: $white;
                }
            }
        }

    }

    // second level animation

    ul.main-nav--sub {
        transform: translateY(pxToVw(20px));
        opacity: 0;
        transition: all .33s ease-in-out;
        visibility: hidden;
    }
    ul.main-nav--first-level {
        li {
            &:hover,
            &:focus-within {
                & > ul.main-nav--sub {
                    visibility: visible;
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }

    // special submenu position for last and 2nd to last main level items

    /*ul.main-nav--first-level {
      & > li:not(.li-button) {
        &:last-child,
        &:nth-last-child(2) {
          ul.main-nav--sub {
            left: auto;
            right: 15px;
          }
        }
      }
    }*/

    // plusminus

    $triangleDimension: 8px;
    .main-nav {
        .main-nav--first-level {
            & > li {
                & > .plusminus {
                    display: none;
                }

                .next {
                    width: unset;
                    height: unset;
                    border-radius: unset;
                    display: block;
                    background: none;

                    .next--arrow {
                        border-width: unset;
                        border-color: unset;
                        border-style: unset;
                        margin: 0;
                        @include css-triangle($dropdownColor, up, $triangleDimension);
                        top: calc(100% - #{$triangleDimension});
                        left: 50%;
                        transform: translateY(pxToVw(20px)) translateX(-50%);
                        opacity: 0;
                        transition: all .33s ease-in-out;
                        z-index: 1;
                    }
                }

                &:hover,
                &:focus-within {
                    .next--arrow {
                        transform: translateY(0) translateX(-50%);
                        opacity: 1;
                    }
                }

            }
        }
    }

}