$font-size-root:              18px !default;

// Variables
/*
$wrapper-max-width: (1600px / $font-size-root) * 1rem;
$header-top-height: 100px;
$main-nav-height: 62px;
$main-nav-topbar-height: 60px;
$main-nav-border-color: rgba(108, 130, 145, 0.1);
*/

$font-family-primary: "Zilla Slab";
$font-family-secondary: "Barlow Condensed";

$color-text: #000;

$color-ci-primary: #dc8c14;
$color-ci-lightgray: #e8e8e8;
$color-ci-gray: #999;

$primary: $color-ci-primary;

$white: #fff;
$black: #000;

// Icons
//$icon-path: 'icons/'; // not used; checks mockups, public, and node_modules/@fortawesome -> see inlineSVG config in config/gulp.js
/*
$icon-list: (       // =KATEGORIE=; =NAME=
    calendar-alt,   // Allgemein; Kalender
    chevron-down,   // Allgemein; Pfeil nach unten
    chevron-right,  // Allgemein; Pfeil nach rechts
    datepicker,     // Allgemein; Datepicker
    facebook-f,     // Allgemein; Facebook
    instagram,      // Allgemein; Instagram
    search,         // Allgemein; Lupe
    toggle-off,     // Allgemein; Schalter aus
    toggle-on,      // Allgemein; Schalter ein
    youtube,        // Allgemein; YouTube
    camera,         // Header; Kamera
    phone,          // Header; Telefon
    mail            //  Header; Email
);
*/


$viewport-width-min: 768px;
$viewport-width-max: 1200px;
