.footer-links {
    &--social {
        display: grid;
        grid-template-columns: 1fr 45px 45px 1fr;
        grid-column-gap: 20px;
        @include fluid-property(margin-bottom, 30px, 40px);

        .btn + .btn:not(.btn-block) {
            margin-left: 0;
        }
    }

    &--list {
        @include fluid-property(margin-bottom, 50px, 60px);

        ul {
            @include reset-list;

            display: flex;
            flex-flow: row wrap;
            li {
                margin-bottom: 10px;

                flex-grow: 1;
                flex-basis: 50%;

                @include media-breakpoint-up(md) {
                    flex-basis: 33%;
                }

                @include media-breakpoint-up(lg) {
                    flex-basis: initial;
                    text-align: center;
                }

                a {
                    color: $color-text;
                    text-decoration: none;
                    padding-left: 20px;
                    position: relative;

                    &:before {
                        @include css-arrow($color-ci-primary, "right", 7px, 1px);
                        content: '';
                        transform: rotate(45deg) translateY(-50%);
                        position: absolute;
                        left: 0;
                        top: 50%;
                    }
                }
            }
        }
    }
}

.page-footer {
    background: $color-ci-primary;
    &, & h5, & a {
        color: $white;
    }

    p {
        margin-bottom: to-rem(30px);
    }

    &--start {
        display: flex;
        flex-flow: column;

        &--nik {
            order: 5;

            img {
                min-width: 240px;
            }
        }

        &:after {
            order: 6;
            display: block;
            content: '';
            margin-left: -9999px;
            margin-right: -9999px;
        }
    }

    &--ruler {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    &--end {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        padding-top: to-rem(35px);
        padding-bottom: to-rem(40px);
    }
}
