.btn {

}
.btn-outline-secondary {
    @include fluid(font-size, 16px);
    @include fluid(padding-top, 18px);
    @include fluid(padding-right, 18px);
    @include fluid(padding-bottom, 18px);
    @include fluid(padding-left, 18px);
    border: 1px solid rgba($black, .2);
    @include fluid(border-radius, 5px);
    text-transform: uppercase;
    color: black;
    font-family: Barlow Condensed;
    font-weight: 700;
}