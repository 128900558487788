
@mixin reset-list {
    &, & li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

@mixin hover-transition($value: all, $duration: .23s, $easing: ease-in-out) {
    transition: $value $duration $easing;
}


@mixin hyphens-auto($break-word: false) {
    @if $break-word {
        word-break: break-all;

        @supports (hyphens: auto) {
            word-break: normal;
        }
    }
    hyphens: auto;
}

@mixin generateIconSet($icon-list, $color: $white) {
	@for $i from 1 through length($icon-list) {
		$icon-name: nth($icon-list, $i);

		.icon.icon--#{$icon-name} {
			background-image: svg-load('#{$icon-name}.svg', fill=$color);
		}
	}
}

@mixin generateIconSetColorVariants($icon-list, $color, $color-class) {
    @for $i from 1 through length($icon-list) {
        $icon-name: nth($icon-list, $i);

        .icon.icon-color--#{$color-class}.icon--#{$icon-name} {
            background-image: svg-load('#{$icon-name}.svg', fill=$color);
        }
    }
}

@mixin setIcon($icon-name, $color, $size: 1em) {
	display: inline-block;
	vertical-align: middle;
	width: $size;
	height: $size;
	line-height: $size;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;

	background-image: svg-load('#{$icon-name}.svg', fill=$color);
}

@mixin updateIcon($icon-name, $color) {
	background-image: svg-load('#{$icon-name}.svg', fill=$color);
}

@mixin hardware($backface: true, $perspective: 1000) {
    @if $backface {
        backface-visibility: hidden;
    }
    perspective: $perspective;
}

@mixin fluidNav($property, $value) {
    #{$property}: $value;
    @include media-breakpoint-down(sm) {
        #{$property}: pxToVwMobile($value);
    }
}


@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin fluid-line-height($min-vw, $max-vw, $min-line-height, $max-line-height) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-line-height);
    $u4: unit($max-line-height);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            line-height: $min-line-height;
            @media screen and (min-width: $min-vw) {
                line-height: calc(#{$min-line-height} + #{strip-unit($max-line-height - $min-line-height)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                line-height: $max-line-height;
            }
        }
    }
}

@mixin fluid-property($property, $min-size, $max-size, $min-breakpoint: sm, $max-breakpoint: lg) {
    $min-vw: map-get($grid-breakpoints, $min-breakpoint);
    $max-vw: map-get($grid-breakpoints, $max-breakpoint);
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-size);
    $u4: unit($max-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            #{$property}: $min-size;
            @include media-breakpoint-up($min-breakpoint) {
                #{$property}: calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @include media-breakpoint-up($max-breakpoint) {
                #{$property}: $max-size;
            }
        }
    }
}

@mixin custom-button-variant($background, $border, $color, $background-hover, $border-hover, $color-hover) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:hover,&.active {
        color: $color-hover;
        background-color: $background-hover;
        border-color: $border-hover;
    }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

// Show a pseudo element and cover the parent with it
@mixin pseudoCover() {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


@mixin css-arrow($color, $direction, $size: 20px, $borderWidth: 4px) {
    display: inline-block;
    width: $size;
    height: $size;
    border-width: $borderWidth $borderWidth 0 0;
    border-style: solid;
    border-color: $color;
    @if $direction == left {
        transform: rotate(-135deg);
    }
    @if $direction == right {
        transform: rotate(45deg);
    }
    @if $direction == up {
        transform: rotate(-45deg);
    }
    @if $direction == down {
        transform: rotate(135deg);
    }
}


/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */

@mixin fluid($property, $value) {
    #{$property}: pxToVwMobile($value);
    @include media-breakpoint-up(md) {
        #{$property}: $value;
    }
}

/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */

@mixin fluidDiff($property, $mobileValue, $value) {
    #{$property}: pxToVwMobile($mobileValue);
    @include media-breakpoint-up(md) {
        #{$property}: $value;
    }
}

@mixin h4Styles {
    color: $black;
    font-size: $h4-font-size;
    font-weight: bold;
    text-transform: uppercase;
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

@mixin css-arrow($color, $direction, $size: 20px, $borderWidth: 4px) {
    display: inline-block;
    width: $size;
    height: $size;
    border-width: $borderWidth $borderWidth 0 0;
    border-style: solid;
    border-color: $color;
    @if $direction == left {
        transform: rotate(-135deg);
    }
    @if $direction == right {
        transform: rotate(45deg);
    }
    @if $direction == up {
        transform: rotate(-45deg);
    }
    @if $direction == down {
        transform: rotate(135deg);
    }
}