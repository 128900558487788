.areablock--wysiwyg {
        @include media-breakpoint-up(md) {
            margin-bottom: 52px;
        }
}
.lead {
    p {
        font-weight: 600;
        line-height: 1.66667rem;
        font-size: 1.111rem;
    }
}