@import "variables";

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              true !default;
$enable-shadows:              false !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-grid-classes:         true !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
/*
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer / 4,
    2: $spacer / 2,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    15: ($spacer * 0.9375), // 15px
    30: ($spacer * 1.875),	// 30px
    50: ($spacer * 3.125),	// 50px
    60: ($spacer * 3.75),	// 60px
    80: ($spacer * 5),		// 80px
    100: ($spacer * 6.25)	// 100px
);
*/
// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $color-text !default;

// Links
//
// Style anchor elements.

$link-color:                              $color-text !default;
$link-decoration:                         underline !default;
$link-hover-decoration:                   $link-decoration !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1036px,
    xl: 1200px,
    xxl: 1600px,
    xxxl: 1900px // custom added
) !default;

// Container widths
//
// Defines which container widths exist
$container-widths: (
    narrow: 1036px,
    normal: 1570px,
    wide: 100%
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1600px,
    xxxl: 1900px
) !default;

/* Container Paddings & Margins */
$containerMarginMobile: 40px !default;
$containerMarginDesktop: 175px !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           1.875rem !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      $font-family-primary !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            var(--bs-font-sans-serif) !default;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root:              18px !default;
$font-size-root-mobile:       16px !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.666666;
$line-height-base-px:         30px;
$line-height-base-mobile:     1.111111;
$line-height-base-mobile-px:  20px;


$h1-font-size:                $font-size-base * 3.1666 !default; // 57px
$h2-font-size:                $font-size-base * 2.3888 !default; // 43px
$h3-font-size:                $font-size-base * 1.77778 !default; // 32px
$h4-font-size:                $font-size-base * 1.33333 !default; // 24px
$h5-font-size:                $font-size-base !default;
$h6-font-size:                $font-size-base !default;

$h1-min-font-size:            $font-size-base * 2.4444 !default; // 44px
$h2-min-font-size:            $font-size-base * 1.875 !default; // 30px
$h3-min-font-size:            $font-size-base * 1.5625 !default; // 25px
$h4-min-font-size:            $font-size-base * 1.375 !default; // 22px
$h5-min-font-size:            $font-size-base * 1 !default; // 18px
$h6-min-font-size:            $font-size-base !default;

$headings-font-weight: $font-weight-bold;
$headings-color: $color-text;

$lead-font-size:			  $font-size-base * 1.111 !default; // 20px
$lead-min-font-size:		  $font-size-base * 1 !default; // 18px

$small-font-size:             $font-size-base * 0.7777 !default; // 14px
$small-min-font-size:         $font-size-base * 0.6111 !default; // 11px

$small-line-height:           $font-size-base * 1.111 !default;  // 20px
$small-min-line-height:       $font-size-base * 1 !default;      // 18px


// misc components
$navbar-dark-color:                 $white !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;

// Buttons
$btn-font-size: 16px !default;
$btn-line-height: 18px !default;
$btn-font-weight: 600 !default;

$btn-border-radius: 5px !default;
$btn-border-radius-sm: 5px !default;
$btn-border-radius-lg: 8px !default;
//$btn-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), inset 0 0 0 rgba(255, 255, 255, 0), 3px 3px 3px rgba(0, 0, 0, 0.1), -3px -3px 3px rgba(255, 255, 255, 0.8);
// $btn-active-box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.2), inset -3px -3px 3px rgba(255, 255, 255, 0.2), 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(255, 255, 255, 0) !default;
//$btn-white-space: nowrap !default;