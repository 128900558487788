.breadcrumb-nav {
    padding: $grid-gutter-width/2 0;
    border-bottom: 1px solid $color-ci-lightgray;
    margin-bottom: $grid-gutter-width;
    .breadcrumb {
        padding: 0;
        margin: 0;
        justify-content: center;
        background: none;
        font-family: $font-family-secondary;
        font-size: $font-size-sm;
        &-item {
            > a {
                text-decoration: none;
            }
            &.active > a {
                color: $color-ci-gray;
            }
        }
    }
}