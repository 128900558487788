.header {
    &-top {
        font-family: $font-family-secondary;
    }
    &-main {
        background: $color-ci-primary;
        padding: 20px 0;
        &--brand {
            > :first-child {
                img {
                    margin-right: 10px;
                }
            }
        }
        &--nav {
            display: flex;
        }
    }
    &-media {
        &--image {
            text-align: center;
            max-height: 820px;
            overflow: hidden;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.frontpage {
    .header {
        &-media {
            &--image {
                @include fluidDiff(margin-bottom, 60px, 100px);
            }
        }
    }
}

/* Brands */

.header {
    &-main {
        &--brands {
           // @include fluidDiff(max-width, 140px, 333px);
           display: grid;
           grid-template-columns: repeat(2, 1fr);
            @include fluidDiff(grid-column-gap, 30px, 80px);
            a {
                display: grid;
                place-items: center;
            }
        }
    }
}

/* Basic Layout */

header {
    padding-top: pxToVwMobile(60px);
    @include media-breakpoint-up(md) {
        padding-top: 140px;
    }
}

.header-main {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    height: pxToVwMobile(60px);
    @include media-breakpoint-up(md) {
        height: 140px;
    }
    .container {
        display: grid;
        grid-template-columns: 140px auto;
        @include media-breakpoint-up(md) {
            grid-template-columns: 333px auto;
        }
    }
}

/* Actions */

.header-main--menu {grid-area: menu; }
.header-main--search {grid-area: search; }
.header-main--cart {grid-area: cart; }

.header-main {
    &--actions {
        display: grid;
        grid-auto-flow:column;
        justify-self: end;
        align-self: center;

        @include media-breakpoint-down(xxl) {
            @include fluid(grid-column-gap, 25px);
            grid-template-areas: 'search cart menu';
        }

    }
}

/* Main Nav */

/*.header-main--menu {
    display: none;
    @include media-breakpoint-up(xxl) {
        display: block;
    }
}*/
.nav.main-nav {
    /*display: grid;
    grid-auto-flow:column;*/
}
.nav-item {
    margin: 0 18px;
}
.nav-link {
    font-size: 25px;
    line-height: 30px;
    font-family: 'Barlow Condensed';
    font-weight: 600;
}

/* Search */

.header-main--search {
    @include fluid(padding-left, 30px);
    display: grid;
    place-items: center;
}
.toggle-search {
    width: 30px;
    height: 30px;
    display: block;
    position: relative;
    cursor: pointer;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/* Cart Button */

.header-main--cart {
    //@include fluid(padding-left, 60px);
    display: grid;
    place-items: center;
    .btn-group {
        button {
            position: relative;
            width: 30px;
            height: 28.32px;
            padding: 0;
            border: none;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}