.areablock {
    text-align: center;
}


/* Margins */

.margin-bottom-none {
    @include fluid(margin-bottom, 0);
}
.margin-bottom-tiny {
    @include fluid(margin-bottom, 15px);
}
.margin-bottom-small {
    @include fluid(margin-bottom, 30px);
}
.margin-bottom-medium {
    @include fluid(margin-bottom, 50px);
}
.margin-bottom-large {
    @include fluid(margin-bottom, 100px);
}