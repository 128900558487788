.table {
    font-family: $font-family-secondary;
    caption-side: top;
    thead {
        th {
            background: $color-ci-primary;
            color: $white;
            font-size: to-em(18px);
            border-bottom: none;
        }
    }
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}