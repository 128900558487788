footer.footer {
    background: black;
}
.footer-grid{
    color: white;
    text-align: center;
    @include fluidDiff(padding-top, 61px, 100px);
    @include fluidDiff(padding-bottom, 77px, 98px);

    @include fluidDiff(font-size, 15px, 15px);
    @include fluidDiff(line-height, 22px, 22px);

    h4 {
        @include fluidDiff(font-size, 17px, 19px);
        @include fluidDiff(line-height, 20.4px, 22.8px);
        color: white;
        font-family: "Barlow Condensed";
        margin-top: 0;
        @include fluidDiff(margin-bottom, 12px, 10px);
        text-transform: none;
    }
    a {
        color: white;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    address {
        margin-bottom: 0;
    }
}

.footer--block {
    @include fluidDiff(padding-bottom, 60px, 105px);
    @include fluidDiff(padding-left, 40px, 0);
    @include fluidDiff(padding-right, 40px, 0);
}

@include media-breakpoint-down(md) {
    .fb--contact {
        padding-left: pxToVwMobile(96px);
    }
    .fb--clogos {
        padding-left: pxToVwMobile(34px);
        padding-right: pxToVwMobile(34px);
        padding-bottom: pxToVwMobile(25px);
    }
}

.fb--contact {
    text-align: left;
    a {
        display: block;
        @include fluid(line-height, 30px);
        text-decoration: none;
        clear: both;
        @include fluid(margin-bottom, 10px);
    }
}

.icon-square {
    display: inline-block;
    background: white;
    @include fluid(width, 30px);
    @include fluid(height, 30px);
    position: relative;
    @include fluid(margin-right, 14px);
    float: left;
    i {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
    }
}
i.icon.icon--email {
    background-image: svg-load('../images/svg/envelope.svg');
}
i.icon.icon--phone {
    background-image: svg-load('../images/svg/phone.svg');
}

.fb--shops {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;
    ul {
        text-align: left;
    }
    li {
        @include fluidDiff(margin-bottom, 5px, 5px);
        &:before {
            content: '';
            display: inline-block;
            @include fluid(width, 6.396px);
            @include fluid(height, 10.92px);
            background-image: svg-load('../images/svg/arrow.svg');
            @include fluid(margin-right, 10px);
        }
    }
    a {
        font-family: 'Barlow Condensed';
        text-decoration: none;
        @include fluidDiff(font-size, 18px, 18px);
        @include fluidDiff(line-height, 21.6px, 21.6px);
        font-weight: 400;
    }
    .fb--shops-left {
        @include fluidDiff(padding-top, 28px, 0);
    }
    .fb--shops-right {
        @include fluidDiff(padding-left, 25px, 50px);
    }
}


.fb--certificates {
    a {
        &.logo-ifs {
            display: grid;
            place-content: center;
        }
    }
}
.fb--clogos {
    display: grid;
    grid-template-columns: 50% 50%;
}

.fb--payments {
    position: relative;
    @include fluidDiff(padding-left, 0, 0);
    @include fluidDiff(padding-right, 0, 0);
    @include fluidDiff(padding-bottom, 0, 0);
    @include fluidDiff(margin-left, 40px, 0);
    @include fluidDiff(margin-right, 40px, 0);
    @include fluidDiff(margin-bottom, 60px, 105px);


    &:before {
        height: 1px;
        background: rgba(white, .2);
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        width: 100%;
    }
}

.payment-logos {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    @include fluid(width, 171px);
    display: inline-block;
    @include fluidDiff(padding-left, 15px, 15px);
    @include fluidDiff(padding-right, 15px, 15px);
    background: black;
    .pl-container {
        display: grid;
        grid-template-columns: 55% 35%;
        grid-column-gap: pxToVwMobile(10%);
    }
    .paypal {
        @include fluid(margin-top, 5px);
    }
    img {
        width: 100%;
        height: auto;
    }
}


.fb--footer-menu {
    @include fluidDiff(padding-bottom, 0, 0);
    li {
        @include fluidDiff(margin-bottom, 15px, 0);
    }
    a {
        font-family: 'Barlow Condensed';
        text-decoration: none;
        @include fluidDiff(font-size, 18px, 18px);
        @include fluidDiff(line-height, 21.6px, 21.6px);
        font-weight: 400;
    }
}

/* Tablet Up */

@include media-breakpoint-up(md) {
    .footer-grid {
        display: grid;
        padding-left: 40px;
        padding-right: 40px;
        grid-template-columns: 50% 50%;
        grid-template-areas: 'company contact'
            'shops shops'
            'certificates certificates'
            'payments payments'
            'footer-menu footer-menu';
    }
    .fb--company { grid-area: company; }
    .fb--contact { grid-area: contact; }
    .fb--shops { grid-area: shops; }
    .fb--certificates { grid-area: certificates; }
    .fb--payments { grid-area: payments; }
    .fb--footer-menu { grid-area: footer-menu; }

    .fb--shops {
        padding-left: 120px;
        padding-right: 120px;
    }
    .fb--shops-left {
        text-align: right;
        img {
            max-width: 330px;
        }
    }

    .fb--certificates {
        display: grid;
        grid-template-columns: 60% 40%;
        padding-left: 100px;
        padding-right: 100px;
        p {
            text-align: left;
        }
    }
    .fb--footer-menu li {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/* Tablet Only
 */


@include media-breakpoint-between(md, xl) {
    .footer--block {
        padding-bottom: 50px;
    }
    .fb--payments {
        padding-bottom: 0;
        margin-bottom: 50px;
    }
    .fb--footer-menu {
        padding-bottom: 0;
    }
    .fb--contact a,
    .fb--shops .fb--shops-right {
        transform: translateX(20%);
    }
}


@include media-breakpoint-between(xl, xxl) {
    .footer-grid {
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: 25% 25% 25% 25%;
        grid-template-areas: 'company contact shops shops'
            '. certificates certificates .'
            'payments payments payments payments'
            'footer-menu footer-menu footer-menu footer-menu';
    }
    .fb--shops {
        padding: 0 0 130px 0;
    }
    .fb--certificates {
        /*padding-left: 20%;
        padding-right: 20%;*/
        padding-left: 0;
        padding-right: 0;
    }
    .fp--payments {
        margin-left: 30px;
        margin-right: 30px;
    }
    .fb--footer-menu li {
        display: inline-block;
        margin: 0 15px;
    }
}



@include media-breakpoint-up(xxl) {
    .footer-grid {
        max-width: 1600px;
        margin: 0 auto;
        grid-template-columns: 274px 266px 435px 60px 543px;
        grid-template-areas:
            'company contact shops . certificates'
            'payments payments payments payments payments'
            'footer-menu footer-menu footer-menu footer-menu footer-menu';
    }
    .fb--company {
        text-align: left;
    }
    .fb--shops {
        padding-left: 0;
        padding-right: 0;
    }
    .fb--certificates {
        padding-left: 0;
        padding-right: 0;
    }
    .fb--payments {
        margin-bottom: 60px;
    }
    .fb-footer-menu {

    }
    .fb--footer-menu li {
        display: inline-block;
        margin: 0 15px;
    }
}