.category-nav {
    font-family: $font-family-secondary;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.level-0 {
            > li {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                > a {
                    @include h4Styles;
                    display: block;
                    background: $color-ci-primary;
                    padding: 15px 20px;
                }
            }
        }

        &.level-1 {
            padding: 15px 0;

            > li {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                > a {
                    @include h4Styles;
                    display: block;
                    padding-left: 30px;
                }
            }
        }

        &.level-2 {
            padding: 15px 0 5px 0;

            > li {
                margin-bottom: 10px;

                > a {
                    padding-left: 30px;
                    border: 1px solid transparent;
                    &:before {
                        @include css-arrow($black, 'right', 8px, 1px);
                        content: "";
                        position: relative;
                        top: -2px;
                        margin-right: 10px;
                    }
                }
                &.is-current > a,
                > a:hover {
                    border-color: $color-ci-lightgray;
                    &:before {
                        border-color: $color-ci-primary;
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        display: block;

        &, &:hover {
            text-decoration: none;
        }
    }

    li {
        > ul {
            display: none;
        }

        &.is-active > ul {
            display: block;
        }
    }

}