// import config
@import "config/variables";
@import "config/bootstrap";

/**
 * import fonts
 * ------------
 * insert fonts in .build/.fonts
 * execute gulp fonts
 */
@import "fonts/imported-google-fonts";

// import vendor
@import "vendor/bootstrap";
@import "vendor/photoswipe";
// @import "vendor/flatpickr";

// import basic stuff
@import "base/functions";
@import "base/type-checking";
@import "base/mixins";
//@import "basic/animations";
@import "base/helpers";
@import "base/typography";

// import layout
@import "layout/header";
@import "layout/main-container";
@import "layout/content";
@import "layout/footer";

// import components
@import "components/navbar";
@import "components/main-nav";
@import "components/breadcrumb-nav";
@import "components/category-nav";
@import "components/button";
@import "components/table";
@import "components/footer";
@import "components/nav";

// areablocks
@import "areablocks/containers";
@import "areablocks/areablocks";
@import "areablocks/buttons";
@import "areablocks/heading";
@import "areablocks/wysiwyg";
@import "areablocks/teasergrid";
@import "areablocks/imageteaser";

#cart-overview-total,
.dropdown-toggle:after { display: none; }

//@import "components/form";
//@import "components/locale-select";
//@import "components/main-nav";
//@import "components/newsletter-form";
//@import "components/button";
//@import "components/table";
//@import "components/icon";

//

//// pimcore content blocks
//@import "_off/areas/area";
//@import "_off/areas/area--gallery";
//@import "_off/areas/area--image";
//@import "_off/areas/area--teaser-landscape";
//@import "_off/areas/area--teaser-large";
//@import "_off/areas/area--teaser-slider";
//@import "_off/areas/area--table";