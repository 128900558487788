h1, h2, h3, h4, h5
.h1, .h2, .h3, .h4, .h5,
.display-1, .display-2 .display-3, .display-4, .display-5, .display-6 {

    font-family: $font-family-secondary;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;

    a {
        color: inherit;
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    small {
        display: block;
        margin-top: .25em;
        font-size: to-rem(20px);
        line-height: to-rem(30px);
        font-weight: 600;
        text-transform: none;
        color: $color-text;
    }

    &.text-center {
        margin-left: auto;
        margin-right: auto;
    }
}


h1,
.h1,
.display-1 {
    @include fluid-type(to-rem($viewport-width-min), to-rem($viewport-width-max), $h1-min-font-size, $h1-font-size);

    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;
}

h2,
.h2,
.display-2 {
    @include fluid-type(to-rem($viewport-width-min), to-rem($viewport-width-max), $h2-min-font-size, $h2-font-size);

    margin-top: 1em;
    margin-bottom: .5em;
}

h3,
.h3,
.display-3 {
    @include fluid-type(to-rem($viewport-width-min), to-rem($viewport-width-max), $h3-min-font-size, $h3-font-size);

    margin-top: 1em;
    margin-bottom: .5em;
}

h4,
.h4,
.display-4 {
    @include fluid-type(to-rem($viewport-width-min), to-rem($viewport-width-max), $h4-min-font-size, $h4-font-size);

    margin-top: 1em;
    margin-bottom: .5em;
}

h5,
.h5,
.display-5 {
    @include fluid-type(to-rem($viewport-width-min), to-rem($viewport-width-max), $h5-min-font-size, $h5-font-size);

    margin-top: 1em;
    margin-bottom: .5em;
}

h6,
.h6,
.display-6 {
    @include fluid-type(to-rem($viewport-width-min), to-rem($viewport-width-max), $h6-min-font-size, $h6-font-size);

    margin-top: 1em;
    margin-bottom: .5em;
}

small,
.small,
.display-small {
    @include fluid-type(to-rem($viewport-width-min), to-rem($viewport-width-max), $small-min-font-size, $small-font-size);
    @include fluid-line-height(to-rem($viewport-width-min), to-rem($viewport-width-max), $small-min-line-height, $small-line-height);
    font-weight: 400;
}

.lead {
    font-weight: $font-weight-semibold;
    line-height: to-rem(30px);
}

hr {
    position: relative;
    border-top: 1px solid $color-ci-lightgray;
}

a[target="_blank"] {
    color: $color-text;

    &:after {
        content: '';
        display: inline-block;
        background: url('/images/icons/link_arrow.svg');
        margin-left: 5px;
        width: 10px;
        height: 10px;
    }
}